import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/BitmoCardStore';
import FilterBar from 'components/FilterBar';
import { capitalize } from '@material-ui/core/utils';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  containerProfile: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  containerProjects: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
});

const dummy = [
  {
    bitmo: {
      country: 'Canada',
      scope: 7,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 7,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 7,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 2,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 1,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 10,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfa dsdf sdfsdf sdfsd',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
  {
    bitmo: {
      country: 'Canada',
      scope: 3,
      vintage: 2018,
    },
    metadata: {
      name: 'This is a cool project cdasfadsdf sdfsdfsdfsdc fdsklfjlsjf',
      description: 'no worries',
      image: 'ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B',
    },
    quantity: 458907348573,
  },
];

const Bitmos = (props) => {
  const { classes, bitmos, page, children } = props;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes[`container${capitalize(page)}`]}>
        <Grid item container spacing={3} justify='center'>
          {children ? (
            <Grid item xs={11}>
              {children}
            </Grid>
          ) : null}
          <Grid item xs={11}>
            <FilterBar />
          </Grid>
        </Grid>

        <Grid item container wrap='nowrap'>
          <Grid container item xs alignItems='flex-end' justify='center'></Grid>
          <Grid container item xs={11}>
            <Grid container spacing={3}>
              {bitmos &&
                bitmos.map((bitmo, index) => (
                  <Grid key={index} item xs sm={4}>
                    <Card bitmo={bitmo} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container item xs alignItems='flex-end' justify='center'></Grid>
        </Grid>
      </Grid>
    </section>
  );
};

Bitmos.propTypes = {
  classes: PropTypes.object.isRequired,
  bitmos: PropTypes.array,
  children: PropTypes.node,
  page: PropTypes.oneOf(['profile', 'projects']),
};

export default withStyles(styles)(Bitmos);
