import { useCallback, useEffect, useState } from 'react';
import Web3Modal from 'web3modal';
import { INFURA_ID } from 'config';
import { Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';

const NETWORK_NAME = 'mainnet';

function useWeb3Modal(config = {}) {
  const [injectedProvider, setInjectedProvider] = useState();
  const { infuraId = INFURA_ID, NETWORK = NETWORK_NAME } = config;

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal
  const web3Modal = new Web3Modal({
    // network: NETWORK,
    cacheProvider: true,
    theme: 'dark',
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId,
        },
      },
    },
  });

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(newProvider));
  }, [setInjectedProvider]);

  const logoutOfWeb3Modal = useCallback(
    async function() {
      await web3Modal.clearCachedProvider();
      window.location.reload();
    },
    [web3Modal],
  );

  // If user has loaded a wallet before, load it automatically.
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal, web3Modal.cachedProvider]);

  return [injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;

// import { useCallback, useEffect, useState } from 'react';
// import Web3Modal from 'web3modal';
// import { Web3Provider } from '@ethersproject/providers';
// import { getWeb3 } from 'services/web3';
// import WalletConnectProvider from '@walletconnect/web3-provider';
// import { INFURA_ID } from "config";

// // Store
// import { useStore } from 'context';

// const NETWORK_NAME = 'mainnet';

// function useWeb3Modal(config = {}) {
//   const [injectedProvider, setInjectedProvider] = useState();
//   const [signedInAddress, setSignedInAddress] = useState('');
//   const { autoLoad = false, infuraId = INFURA_ID, NETWORK = NETWORK_NAME } = config;

//   // Web3Modal also supports many other wallets.
//   // You can see other options at https://github.com/Web3Modal/web3modal
//   const web3Modal = new Web3Modal({
//     network: NETWORK,
//     cacheProvider: false,
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider,
//         options: {
//           infuraId,
//         },
//       },
//     },
//   });

//   // Open wallet selection modal.
//   const loadWeb3Modal = useCallback(async () => {
//     const newProvider = await web3Modal.connect();

//     // Subscribe to accounts change
//     newProvider.on('accountsChanged', (accounts) => {
//       if (accounts.length > 0) {
//         setSignedInAddress(accounts[0]);
//       }
//     });

//     // Subscribe to chainId change
//     newProvider.on('chainChanged', (chainId) => {
//       window.location.reload();
//     });

//     setInjectedProvider(new Web3Provider(newProvider));
//     setSignedInAddress(newProvider.selectedAddress);
//   }, [setInjectedProvider]);

//   const logoutOfWeb3Modal = useCallback(
//     async function () {
//       setSignedInAddress('');
//       await web3Modal.clearCachedProvider();
//       window.location.reload();
//     },
//     [web3Modal]
//   );

//   // If user has loaded a wallet before, load it automatically.
//   useEffect(() => {
//     if (web3Modal.cachedProvider) {
//       loadWeb3Modal();
//     }
//   }, [loadWeb3Modal, web3Modal.cachedProvider]);

//   return [injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal];
// }

// export default useWeb3Modal;
