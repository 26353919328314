import axios from 'axios';
import ipfsClient from 'ipfs-http-client';

const pinataApiKey = process.env.REACT_APP_PINATA_API_KEY;
const pinataSecretApiKey = process.env.REACT_APP_PINATA_SECRET_KEY;

const IPFS = {
  baseGateway: 'ipfs/',
  client: ipfsClient({ host: 'ipfs.infura.io', port: '5001', protocol: 'https' }),

  async saveFileToIPFS(file) {
    const options = {
      // recursive: false,
      // progress: (prog) => console.log(`received: ${prog}`)
    };

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        const buffer = Buffer.from(reader.result);
        this.client
          .add(buffer, options)
          .then((res) => {
            this.pinByPinata(res.path);
            this.pinByInfura(res.path);

            resolve(`ipfs://${this.baseGateway}${res.path}`);
          })
          .catch((error) => {
            reject(error);
          });
      };
    });
  },

  async saveDocsToIPFS(filesList) {
    return Promise.all([...filesList].map((file) => file).map((file) => this.saveFileToIPFS(file)));
  },

  async saveJsonFile({ metadata }) {
    const options = {
      // recursive: false,
      // progress: (prog) => console.log(`received: ${prog}`)
    };

    return new Promise((resolve, reject) => {
      this.client
        .add(JSON.stringify(metadata), options)
        .then((res) => {
          this.pinByPinata(res.path);
          this.pinByInfura(res.path);

          resolve(`${this.baseGateway}${res.path}`);
        })
        .catch((error) => reject(error));
    });
  },

  async fetchJsonFromIpfs(ipfsPath) {
    if (!ipfsPath) return;

    try {
      const ipfsHash = ipfsPath && ipfsPath.replace('ipfs://ipfs/', '');
      const proxyurl = 'https://cors-anywhere.herokuapp.com/'; // will only use in development
      const json = await (await fetch(`https://ipfs.io/ipfs/${ipfsHash}`)).json();

      return json;
    } catch (error) {
      console.error('Could not fetch JSON from IPFS', error);
      throw error;
    }
  },

  async pinByInfura(hash) {
    axios
      .get('https://ipfs.infura.io:5001/api/v0/pin/add?arg=/ipfs/' + hash)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  pinByPinata(hash) {
    axios
      .post(
        'https://api.pinata.cloud/pinning/pinHashToIPFS',
        { hashToPin: hash },
        {
          headers: {
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataSecretApiKey,
          },
        },
      )
      .then((res) => {
        console.log(res);
      })
      .catch(function(error) {
        console.log(error);
      });
  },
};

export default IPFS;

// https://github.com/valist-io/valist/blob/master/eth/contracts/ValistOrganization.sol
// https://github.com/gigamesh/Splitter/tree/master/packages/react-app/src/hooks
