import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { amber, green } from '@material-ui/core/colors';

const styles = (theme) => ({
  error: { backgroundColor: theme.palette.error.dark },
  info: { backgroundColor: theme.palette.primary.main },
  success: { backgroundColor: green[600] },
  warning: { backgroundColor: amber[700] },
});

const Provider = (props) => {
  const { classes, children, ...other } = props;

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          className={classes.close}
          // onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      {...other}
    >
      {children}
    </SnackbarProvider>
  );
};

Provider.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Provider);
