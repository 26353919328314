import { hexlify } from '@ethersproject/bytes';
import { parseUnits } from '@ethersproject/units';

// https://docs.blocknative.com/notify
import Notify from 'bnc-notify';

// Store
import { useStore } from 'context';

export default function Notifier(provider, gasPrice, etherscan) {
  const { actions } = useStore();

  if (typeof provider !== 'undefined') {
    // eslint-disable-next-line consistent-return
    return async (tx) => {
      const signer = provider.getSigner();
      const network = await provider.getNetwork();
      console.log('network', network);

      const options = {
        dappId: process.env.REACT_APP_BLOCK_NATIVE,
        system: 'ethereum',
        networkId: network.chainId,
        darkMode: true,
        transactionHandler: (txInformation) => {
          console.log('HANDLE TX', txInformation);
        },
      };
      const notify = Notify(options);

      let etherscanNetwork = '';
      if (network.name && network.chainId > 1) {
        etherscanNetwork = network.name + '.';
      }

      const etherscanTxUrl = 'https://' + etherscanNetwork + 'etherscan.io/tx/';

      try {
        let result;
        if (tx instanceof Promise) {
          console.log('AWAITING TX', tx);
          result = await tx;
        } else {
          if (!tx.gasPrice) {
            tx.gasPrice = gasPrice || parseUnits('4.1', 'gwei');
          }
          if (!tx.gasLimit) {
            tx.gasLimit = hexlify(120000);
          }
          console.log('RUNNING TX', tx);
          result = await signer.sendTransaction(tx);
        }
        console.log('RESULT:', result);
        // console.log("Notify", notify);

        // if it is a valid Notify.js network, use that, if not, just send a default notification
        if ([1, 3, 4, 5, 42, 100].indexOf(network.chainId) >= 0) {
          const { emitter } = notify.hash(result.hash);
          emitter.on('all', (transaction) => {
            return {
              onclick: () => window.open((etherscan || etherscanTxUrl) + transaction.hash),
            };
          });
        } else {
          // enqueueSnackbar(`Local Transaction Sent: ${result.hash}`, { variant: 'success' });
        }

        actions.setLoading(false);

        return result;
      } catch (e) {
        console.log(e);
        console.log('Transaction Error:', e.message);
        // enqueueSnackbar(`Transaction Error: ${e.message}`, { variant: 'error' });
      }
    };
  }
}
