import React from 'react';
import clsx from 'clsx';
import Typography from 'components/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Link, Grid } from '@material-ui/core';

// assets
import Logo from 'static/logo.svg';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { ReactComponent as FootrerBottom } from 'static/home/FootrerBottom.svg';

const Copyright = () => {
  return (
    <>
      {'© '}
      <Link color='inherit' href='https://bitmo.org/'>
        Bitmo
      </Link>
{' '}
      {new Date().getFullYear()}
      <br />
      Issue, retire and transfer carbon credits.
      <br />
      Powered by blockchain technology.
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.secondary.background,
  },
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  icons: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    textDecoration: 'none',
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs sm={4} md={2}>
            {/* <Grid item xs sm={4} md={2}> */}
            <Grid container direction='column' justify='center' spacing={2}>
              <Grid item className={classes.icons}>
                <a href='/' className={classes.icon}>
                  <img src={Logo} style={{ maxHeight: '100%', height: '40px' }} alt={Logo} />
                </a>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Typography variant='h6' marked='left' gutterBottom>
              Information
            </Typography>
            <ul className={classes.list}>
              {/* <li className={classes.listItem}>
                <Link href='/vision/'>Our Vision</Link>
              </li> */}
              <li className={classes.listItem}>
                <Link href='https://www.blockchainforclimate.org/team'>Our Team</Link>
              </li>
            </ul>
          </Grid>

          {/* <Grid item xs>
            <Typography variant='h6' marked='left' gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href='/terms/'>Terms</Link>
              </li>
              <li className={classes.listItem}>
                <Link href='/privacy/'>Privacy</Link>
              </li>
            </ul>
          </Grid> */}

          <Grid item xs>
            {/* <Grid item xs sm={4} md={2}> */}
            <Grid container direction='column'>
              <FootrerBottom />
            </Grid>
          </Grid>

          <Grid item xs>
            {/* <Grid item xs sm={4} md={2}> */}
            <Grid container justify='flex-end' alignItems='center' spacing={2} nowrap='true'>
              <Link href='https://www.blockchainforclimate.org/'>
                <LanguageIcon className={clsx(classes.icon, classes.socialIcons)} />
              </Link>
              <Link href='https://twitter.com/bitmo_'>
                <TwitterIcon className={clsx(classes.icon, classes.socialIcons)} />
              </Link>
              <Link href='https://www.linkedin.com/company/blockchain-for-climate/'>
                <TelegramIcon className={clsx(classes.icon, classes.socialIcons)} />
              </Link>
              <Link href='https://www.linkedin.com/company/blockchain-for-climate/'>
                <LinkedInIcon className={clsx(classes.icon, classes.socialIcons)} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
