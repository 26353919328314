import React from 'react';
import Footer from 'views/Footer';
import BurnView from 'views/BurnView';

const Burn = ({ match }) => {
  const {
    params: { tokenId },
  } = match;

  return (
    <>
      <BurnView tokenId={tokenId} />
      <Footer />
    </>
  );
};

export default Burn;
