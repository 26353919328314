import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Grid } from '@material-ui/core';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// assets
import HomeImage from 'static/home2.jpg';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(9),
  },
  image: {
    height: '90vmin',
    background: `url(${HomeImage}) no-repeat center / cover`,
  },
  hook: {
    marginRight: 64,
    marginBottom: 64,
  },
  explore: {
    borderColor: 'white',
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.secondary.background,
      borderColor: theme.palette.secondary.main,
    },
  },
});

const LandingHero = (props) => {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Grid container wrap='nowrap' className={classes.container}>
        <Grid container item xs alignItems='flex-end' justify='center' />

        <Grid container item xs={11} className={classes.image}>
          <Grid
            item
            container
            direction='column'
            justify='flex-end'
            justifyContent='center'
            alignItems='center'
            className={classes.hook}
          >
            <Grid item>
              <Typography
                variant='h4'
                style={{
                  background: 'white',
                  webkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Welcome to the BITMO Platform
              </Typography>
              <Typography
                variant='subtitle1'
                style={{
                  background: 'white',
                  webkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Built to enable National Parties representing the countries of the world to issue
              </Typography>
              <Typography
                variant='subtitle1'
                style={{
                  background: 'white',
                  webkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                and exchange Paris Agreement Article 6.2 carbon credits on the Ethereum blockchain.
              </Typography>
            </Grid>

            <Grid item>
              <Button
                color='secondary'
                href='/issue'
                variant='outlined'
                shape='round'
                size='small'
                disableElevation
                href='/projects'
                endIcon={<ArrowForwardIcon />}
                className={classes.explore}
              >
                Start Exploring
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs alignItems='flex-end' justify='center' />
      </Grid>
    </section>
  );
};

LandingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingHero);
