import React from 'react';
import { utils } from 'ethers';
import PropTypes from 'prop-types';
import { getCode } from 'country-list';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { Grid, Card, CardMedia, Divider, Paper, Button, Link } from '@material-ui/core';

// Store
import { useStore } from 'context';
import { useIsCurrentOwner } from 'hooks';

// Config
import { COMMON } from 'config';
import { doc } from 'prettier';

const styles = (theme) => ({
  media: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  opensea: {
    margin: 'auto',
    display: 'block',
    height: '100%',
    width: '160px',
    borderRadius: '5px',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
  },
  flag: {
    width: 64,
    height: 32,
    maxWidth: 64,
    maxHeight: 32,
    marginLeft: 'auto',
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-3),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#fff',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 32,
      marginTop: 0,
      marginRight: theme.spacing(-1),
      transform: 'translateX(-8px)',
    },
  },
  bitmoOwnership: {
    padding: theme.spacing(2),
  },
  paper: {
    margin: 'auto',
    width: '100%',
    maxHight: 700,
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 700,
      marginBottom: 0,
    },
  },
});

const FLAG = (country) => {
  const countryName = hexToString(country);

  // there is two koreas duh
  const code = countryName === 'Korea' ? 'kr' : getCode(countryName)?.toLowerCase();

  return require(`services/flags/4x3/${code}.svg`); // TODO: add a fail safe import of an image placeholder
};

const hexToString = (hex) => utils.parseBytes32String(hex);
const ipfsPathToHash = (path) => path.replace('ipfs://ipfs/', '');
const numberFormat = (quantity) => quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const BitmoViewStore = React.memo(
  ({
    classes,
    bitmo: {
      bitmo: { country },
      metadata: { name, description, image, documents, attributes },
      quantity,
      tokenId,
    },
  }) => {
    const {
      state: { readContracts, walletAddress },
    } = useStore();
    const {
      opensea: { OSURI },
    } = COMMON;
    const { isTokenOwner } = useIsCurrentOwner(readContracts, tokenId, walletAddress);

    const docs =
      documents &&
      documents?.map((url) => (
        <Link
          href={`https://cloudflare-ipfs.com/ipfs/${ipfsPathToHash(url)}`}
          title='Document'
          target='_blank'
          key={url}
        >
          <DescriptionIcon fontSize='large' />
        </Link>
      ));

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm container>
            <Grid item xs={12}>
              <CardMedia
                className={classes.media}
                image={`https://cloudflare-ipfs.com/ipfs/${ipfsPathToHash(image)}`}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} sm container direction='column' justify='center'>
            <Grid item xs container spacing={2}>
              <Typography gutterBottom variant='h4'>
                {name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm container>
              <Grid item xs container direction='column' spacing={2}>
                <Card className={classes.bitmoOwnership}>
                  <Grid item xs={12} sm container wrap='nowrap'>
                    <Grid item xs container direction='column' spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant='subtitle1'>
                          {`Creator: ${hexToString(country)}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2' gutterBottom>
                          {`Supply: ${numberFormat(quantity)} of ${numberFormat(quantity)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <CardMedia className={classes.flag} image={FLAG(country)} />
                    </Grid>
                  </Grid>
                </Card>
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle1'>
                    Description:
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    {description}
                  </Typography>
                </Grid>
                <Divider />
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle1'>
                    BITMOs Properties:
                  </Typography>
                  {attributes &&
                    attributes.map(({ key, value }) => (
                      <Typography key={key} variant='body2' gutterBottom>
                        {value ? `${key}: ${value}` : null}
                      </Typography>
                    ))}
                </Grid>
                {documents && documents.length ? (
                  <Grid item xs>
                    <Typography gutterBottom variant='subtitle1'>
                      Project & Audit Documents:
                    </Typography>
                    {docs}
                  </Grid>
                ) : null}
                {isTokenOwner && (
                  <Grid item container xs spacing={2} justify='center'>
                    <Grid item xs>
                      <Button
                        color='primary'
                        className={classes.filledButton}
                        variant='contained'
                        type='submit'
                        href={`/bitmo/${tokenId}/burn`}
                        style={{ width: '100%' }}
                      >
                        Retire
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        color='primary'
                        className={classes.filledButton}
                        variant='contained'
                        type='submit'
                        href={`/bitmo/${tokenId}/transfer`}
                        style={{ width: '100%' }}
                      >
                        Transfer
                      </Button>
                    </Grid>

                    <Grid item xs>
                      <Link href={`${OSURI}/${tokenId}`} title='Buy on OpenSea' target='_blank'>
                        <CardMedia
                          className={classes.opensea}
                          image={`https://storage.googleapis.com/opensea-static/opensea-brand/listed-button-white.png`}
                        />
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

BitmoViewStore.propTypes = {
  classes: PropTypes.object.isRequired,
  bitmo: PropTypes.object.isRequired,
};

export default withStyles(styles)(BitmoViewStore);
