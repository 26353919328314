import React from 'react';
import PropTypes from 'prop-types';
import { useBitmo } from 'hooks';
import Card from 'components/TransferViewStore';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

// Store
import { useStore } from 'context';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
});

const TransferView = React.memo((props) => {
  const {
    state: { readContracts },
  } = useStore();
  const { classes, tokenId } = props;
  const bitmo = useBitmo(readContracts, tokenId);

  return (
    <section className={classes.root}>
      {bitmo && (
        <Container className={classes.container}>
          <Card bitmo={bitmo} />
        </Container>
      )}
    </section>
  );
});

TransferView.propTypes = {
  classes: PropTypes.object.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default withStyles(styles)(TransferView);
