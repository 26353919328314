import React from 'react';
import Footer from 'views/Footer';
import Grid from '@material-ui/core/Grid';
import LandingHero from 'views/LandingHero';
import ProductHook from 'views/ProductHook';
import { makeStyles } from '@material-ui/core/styles';
import ProductFeatures from 'views/ProductFeatures';
import ProductHighlights from 'views/ProductHighlights';

// assets
import { ReactComponent as BigLeaf } from 'static/home/BigLeaf.svg';
import { ReactComponent as SmallLeaves } from 'static/home/SmallLeaves.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
  },
  BigLeaf: {
    marginRight: '2 10',
    alignItems: 'flex-end',
  },
  SmallLeaves: {
    // left: "50%",
    // position: "relative",
    // marginLeft: "-50vw",
    // marginRight: "-50vw",
    alignItems: 'flex-start',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <LandingHero />
      <section className={classes.root}>
        <Grid container wrap='nowrap' className={classes.container}>
          <Grid container item xs={2} className={classes.BigLeaf}>
            {/* <BigLeaf /> */}
          </Grid>
          <Grid container item xs={12}>
            <ProductHook />
            <ProductHighlights />
            {/* <ProductFeatures /> */}
          </Grid>
          <Grid container item xs={2} className={classes.SmallLeaves}>
            {/* <SmallLeaves /> */}
          </Grid>
        </Grid>
      </section>
      <Footer />
    </>
  );
};

export default Home;
