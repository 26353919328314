import React from 'react';
import Bitmos from 'views/Bitmos';
import { useAllBitmos } from 'hooks';

// Store
import { useStore } from 'context';

const Market = () => {
  const {
    state: { readContracts },
  } = useStore();
  const allBitmos = useAllBitmos(readContracts);

  return <>{allBitmos && <Bitmos bitmos={allBitmos} page='projects' />}</>;
};

export default Market;
