import React, { useState } from 'react';
import { useAdmin } from 'hooks';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Grid, Paper, Typography, IconButton, Menu, MenuItem, Fade } from '@material-ui/core';

// assets
import { ReactComponent as Browse } from 'static/profile/browse.svg';
import { ReactComponent as Avatar } from 'static/profile/avatar.svg';

// Store
import { useStore } from 'context';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '100%',
      margin: theme.spacing(1),
      height: theme.spacing(32),
    },
    marginTop: theme.spacing(10),
  },
  avatar: {
    [theme.breakpoints.up('md')]: {
      width: 128,
      height: 128,
    },
  },
  browse: {
    [theme.breakpoints.up('md')]: {
      width: 256,
      height: 256,
    },
  },
});

const TopProfileBanner = (props) => {
  const {
    state: { walletAddress, readContracts },
  } = useStore();
  const { classes } = props;
  const isAdmin = useAdmin(readContracts, walletAddress);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const truncateAddress = (addr) => {
    const prefix = addr.substring(0, 6);
    const suffix = addr.substring(addr.length - 4);

    return prefix + '...' + suffix;
  };

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='center'
        className={classes.container}
      >
        <Grid item container xs justify='flex-start' alignItems='center' spacing={2}>
          <Grid item>
            <Avatar className={classes.avatar} />
          </Grid>
          <Grid item>
            <Typography variant='h6'>
              {walletAddress ? truncateAddress(walletAddress) : 'Profile'}
            </Typography>
          </Grid>
          <Grid item>
            {isAdmin && (
              <IconButton
                aria-label='more'
                aria-controls='fade-menu'
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu
              id='fade-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {/* Replace with settings page */}
              <MenuItem component={Link} to='/profile/roles'>
                Manage Roles
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Grid item container xs justify='flex-end'>
          <Browse className={classes.browse} />
        </Grid>
      </Grid>
    </Paper>
  );
};

TopProfileBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopProfileBanner);
