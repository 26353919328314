import { makeStyles, Container, Grid } from '@material-ui/core';
import React from 'react';
import Footer from 'views/Footer';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
  },
}));

const User = () => {
  const classes = makeStyles();

  return (
    <>
      <section className={classes.root}>
        <Container className={classes.container}>
          <Grid direction='column' container spacing={2}>
            <Grid item xs className={classes.item}>
              <Typography variant='h6' className={classes.title}>
                The BiTMO Platform
              </Typography>
            </Grid>

            <Grid item xs className={classes.item}>
              <Typography variant='h5' className={classes.title}>
                Mission: Putting Paris Agreement carbon markets on the Ethereum blockchain, enabling
                national governments to issue and exchange their Article 6.2 Internationally
                Transferred Mitigation Outcome (ITMO) carbon credits as Non-Fungible Tokens (NFTs.)
                Vision: Connecting the national carbon accounts of the world to enable cross border
                collaboration on emissions reductions.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer className={classes.container} />
    </>
  );
};

export default User;
