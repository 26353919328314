import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    borderRadius: 20,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamilySecondary,
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(14),
    boxShadow: 'none',
    '&:active, &:focus': {
      boxShadow: 'none',
    },
  },
  sizeSmall: {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  },
  sizeLarge: {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  },
}))(Button);

// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import MuiButton from '@material-ui/core/Button';
// import { withStyles } from '@material-ui/core/styles';

// const styles = theme => ({
//   root: {
//     borderRadius: 20,
//     fontWeight: theme.typography.fontWeightMedium,
//     fontFamily: theme.typography.fontFamilySecondary,
//     padding: theme.spacing(1, 2),
//     fontSize: theme.typography.pxToRem(14),
//     boxShadow: 'none',
//     '&:active, &:focus': {
//       boxShadow: 'none',
//     },
//   },
//   sizeSmall: {
//     padding: theme.spacing(1, 3),
//     fontSize: theme.typography.pxToRem(13),
//   },
//   sizeLarge: {
//     padding: theme.spacing(2, 5),
//     fontSize: theme.typography.pxToRem(16),
//   },
//   customColor: {
//     background: 'linear-gradient(180deg, #608BF6, #9187FD)',
//   },
// });

// const Button = props => {
//   // const [color, setColor] = useState('primary');
//   const { classes, children, isCustomColor, color, bgColor, ...other } = props;

//   return (
//     <MuiButton color={color} {...other}>
//       {children}
//     </MuiButton>
//   )
// };

// Button.propTypes = {
//   classes: PropTypes.object.isRequired,
//   children: PropTypes.node,
//   color: PropTypes.string,
//   isCustomColor: PropTypes.bool,
// };

// export default withStyles(styles)(Button);
