import React, { useState } from 'react';
import { useCouncil } from 'hooks';
import Logo from 'static/logo.svg';
import Button from 'components/Button';
import AppBar from 'components/AppBar';
import { Link } from 'react-router-dom';
import Toolbar from 'components/Toolbar';
import Typography from 'components/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { IconButton, Menu, MenuItem, Fade } from '@material-ui/core';

// Store
import { useStore } from 'context';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  filledButton: {
    background: theme.palette.secondary.background,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 4,
  },
  searchButton: {
    background: theme.palette.secondary.background,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 4,
    borderRadius: theme.spacing(2), // 16px
  },
  outlineButton: {
    borderColor: theme.palette.secondary.main,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 4,
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.secondary.background,
      borderColor: theme.palette.secondary.main,
    },
  },
  plainButton: {
    borderColor: theme.palette.secondary.main,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 4,
  },
});

const NavBar = (props) => {
  const {
    state: { walletAddress, readContracts },
  } = useStore();
  const isCouncil = useCouncil(readContracts, walletAddress);
  const { classes, web3Modal, loadWeb3Modal, logoutOfWeb3Modal } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const truncateAddress = (addr) => {
    const prefix = addr.substring(0, 6);
    const suffix = addr.substring(addr.length - 4);

    return prefix + '...' + suffix;
  };

  const LoggedOutNav = (
    <>
      <Button href='/projects' className={classes.plainButton}>
        Projects
      </Button>
      <Button href='/issue' className={classes.plainButton}>
        Issue
      </Button>

      <Button
        href='https://unfccc.int/files/meetings/paris_nov_2015/application/pdf/paris_agreement_english_.pdf'
        className={classes.plainButton}
      >
        Paris Agreement
      </Button>

      <Button
        key='loginbutton'
        color='primary'
        className={classes.outlineButton}
        variant='outlined'
        shape='round'
        size='small'
        disableElevation
        onClick={loadWeb3Modal}
      >
        connect
      </Button>
    </>
  );

  const LoggedInNav = (
    <>
      <Button href='/projects' className={classes.plainButton}>
        Projects
      </Button>
      {/* <Button href='/explorer' className={classes.plainButton}>
        BitmoScan
      </Button> */}
      <IconButton
        className={classes.searchButton}
        aria-label='search button'
        aria-haspopup='true'
        color='inherit'
        href='/search'
      >
        <SearchIcon />
      </IconButton>

      {isCouncil && (
        <Button
          color='primary'
          className={classes.outlineButton}
          href='/issue'
          variant='outlined'
          shape='round'
          size='small'
          disableElevation
        >
          Issue Bitmo
        </Button>
      )}

      <Button
        key='logoutbutton'
        className={classes.outlineButton}
        shape='round'
        size='small'
        onClick={handleClick}
      >
        {walletAddress ? truncateAddress(walletAddress) : 'logout'}
      </Button>

      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={logoutOfWeb3Modal}>logout</MenuItem>
      </Menu>

      <IconButton
        className={classes.plainButton}
        aria-label='search button'
        aria-haspopup='true'
        color='inherit'
        href='/profile'
      >
        <AccountCircle color='secondary' />
      </IconButton>
    </>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        style={{
          background: 'white',
          boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        }}
      >
        <Toolbar>
          <Link to='/'>
            <img src={Logo} alt={Logo} style={{ maxHeight: '100%', height: '40px' }} />
          </Link>
          <Typography className={classes.title} variant='h6' noWrap>
            Bitmo
          </Typography>

          <div className={classes.root} />

          <div className={classes.sectionDesktop}>
            {web3Modal && web3Modal.cachedProvider ? LoggedInNav : LoggedOutNav}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(NavBar);
