import React from 'react';
import Footer from 'views/Footer';
import BitmoView from 'views/BitmoView';

const Bitmo = ({ match }) => {
  const {
    params: { tokenId },
  } = match;

  return (
    <>
      <BitmoView tokenId={tokenId} />
      <Footer />
    </>
  );
};

export default Bitmo;
