import { useState, useCallback, useEffect } from 'react';

const useAdmin = (readContracts, walletAddress) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const execute = useCallback(() => {
    const isAdmin = () => readContracts.Bitmo.isAdmin(walletAddress);

    isAdmin().then((result) => setIsAdmin(result));
  }, [readContracts, walletAddress]);

  useEffect(() => {
    readContracts && execute();
  }, [execute, readContracts]);

  return isAdmin;
};

export default useAdmin;
