import PropTypes from 'prop-types';
import React from 'react';
import Typography from 'components/Typography';
import { Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// assets
import { ReactComponent as Feature1 } from 'static/home/Feature1.svg';
import { ReactComponent as Feature2 } from 'static/home/Feature2.svg';
import { ReactComponent as Feature3 } from 'static/home/Feature3.svg';

const content = [
  {
    title: 'Accounting of NDC and Carbon Markets Made Easy',
    subtitle: 'Accounting of NDC and Carbon Markets Made Easy',
    image: (props) => <Feature1 className={props} />,
    direction: 'row',
  },
  {
    title: 'Accounting of NDC and Carbon Markets Made Easy',
    subtitle: 'Issue Standardized Carbon Credits Effortlessly',
    image: (props) => <Feature2 className={props} />,
    direction: 'row-reverse',
  },
  {
    title: 'Accounting of NDC and Carbon Markets Made Easy',
    subtitle: 'Freely Trade Carbon Credit Globally Via Smart Contracts',
    image: (props) => <Feature3 className={props} />,
    direction: 'row',
  },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  itemContainer: {
    alignItems: 'center',
  },
  item: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'justify',
    padding: theme.spacing(5),
  },
});

const ProductFeatures = (props) => {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={1}>
          {content.map(({ title, subtitle, image, direction }, i) => (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={classes.itemContainer}
              direction={direction}
              key={i}
            >
              <Grid item xs={6} className={classes.item}>
                {image(classes.image)}
              </Grid>

              <Grid item xs={6} className={classes.item}>
                <Typography variant='h6' className={classes.title}>
                  {title}
                </Typography>
                <Typography variant='h5' className={classes.description}>
                  {'From the latest trendy boutique hotel to the iconic palace with XXL pool'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                  {', go for a mini-vacation just a few subway stops away from your home.'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

ProductFeatures.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductFeatures);
