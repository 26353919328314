import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

// assets
import { ReactComponent as Feature1 } from 'static/home/icon-feature-1.svg';
import { ReactComponent as Feature2 } from 'static/home/icon-feature-2.svg';
import { ReactComponent as Feature3 } from 'static/home/icon-feature-3.svg';
import { ReactComponent as Feature4 } from 'static/home/icon-feature-4.svg';
import { ReactComponent as Feature5 } from 'static/home/icon-feature-5.svg';

const content = [
  {
    subtitle: 'Accurate carbon accounting of ITMOs bought and sold.',
    image: (props) => <Feature1 className={props} />,
  },
  {
    subtitle: 'Issue and exchange ITMOs as NFTs on Ethereum.',
    image: (props) => <Feature2 className={props} />,
  },
  {
    subtitle: 'Leverage Ethereum’s broad ecosystem of blockchain tools.',
    image: (props) => <Feature3 className={props} />,
  },
  {
    subtitle: 'Connect emissions reductions opportunities with capital and demand.',
    image: (props) => <Feature4 className={props} />,
  },
  {
    subtitle: 'Trusted, transparent carbon markets mean more climate action.',
    image: (props) => <Feature5 className={props} />,
  },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

const ProductHighlights = (props) => {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={1}>
          {content.map((item, i) => (
            <Grid item xs key={i}>
              <div className={classes.item}>
                {item.image(classes.image)}
                <Typography variant='subtitle1' className={classes.title}>
                  {item.subtitle}
                </Typography>
              </div>
            </Grid>
          ))}
          <Grid container spacing={1}>
            <Typography
              variant='h6'
              style={{
                textAlign: 'center',
                background: '-webkit-linear-gradient(180deg, #608BF6, #9187FD)',
                webkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              The BITMO Platform is now live on Ethereum. If you represent your country’s National
              Party, please reach out today to explore the Platform, join our National Party Working
              Group, and whenever you’re ready, start issuing and exchanging your ITMOs on the
              Ethereum blockchain.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

ProductHighlights.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHighlights);
