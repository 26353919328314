import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, CardMedia, Paper, Button, TextField } from '@material-ui/core';

// Store
import { useStore } from 'context';

const schema = yup.object().shape({
  quantity: yup
    .number()
    .positive()
    .integer()
    .required('Provide the amount of credits you want to issue'),
});

const styles = (theme) => ({
  media: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  flag: {
    width: 64,
    height: 32,
    maxWidth: 64,
    maxHeight: 32,
    marginLeft: 'auto',
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-3),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#fff',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 32,
      marginTop: 0,
      marginRight: theme.spacing(-1),
      transform: 'translateX(-8px)',
    },
  },
  bitmoOwnership: {
    padding: theme.spacing(2),
  },
  paper: {
    margin: 'auto',
    width: '100%',
    maxHight: 618,
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 618,
      marginBottom: 0,
    },
  },
  filledButton: {
    width: '100%',
  },
  form: {
    width: '100%',
    margin: '0 auto',
  },
});

const ipfsPathToHash = (path) => path.replace('ipfs://ipfs/', '');

const BurnViewStore = React.memo(
  ({
    classes,
    bitmo: {
      metadata: { image },
      quantity,
      tokenId,
    },
  }) => {
    const { actions } = useStore();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => actions.burnBitmo({ tokenId, ...data });

    return (
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm container>
              <Grid item xs={12}>
                <CardMedia
                  className={classes.media}
                  image={`https://cloudflare-ipfs.com/ipfs/${ipfsPathToHash(image)}`}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} container direction='column' justify='center'>
              <Grid item container xs alignItems='flex-end'>
                <Typography gutterBottom variant='h4'>
                  Retire BITMOs
                </Typography>
              </Grid>
              <Grid item container xs direction='column' alignItems='flex-start'>
                <Grid item container xs alignItems='flex-end'>
                  <Typography variant='h6'>Quantity</Typography>
                </Grid>
                <Grid item container xs>
                  <TextField
                    id='quantity'
                    required
                    name='quantity'
                    inputRef={register}
                    placeholder='10000'
                    margin='normal'
                    variant='outlined'
                    error={errors.quantity ? true : false}
                    helperText={errors.quantity ? errors.quantity.message : ''}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid item container xs alignItems='flex-start'>
                <Button
                  color='primary'
                  className={classes.filledButton}
                  variant='contained'
                  type='submit'
                >
                  Retire
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  },
);

BurnViewStore.propTypes = {
  classes: PropTypes.object.isRequired,
  bitmo: PropTypes.object.isRequired,
};

export default withStyles(styles)(BurnViewStore);
