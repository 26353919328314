import React, { createContext, useReducer, useContext } from 'react';
import { useActions } from './actions';
import { reducer, initialState } from './reducer';

const Store = createContext();
const compose = (...funcs) => (x) => funcs.reduceRight((composed, f) => f(composed), x);

const customMiddleware = (store) => (next) => (action) => {
  // console.log('Action Triggered: ', action?.type);
  // console.log(action);
  next(action);
};

const CreateStore = (reducer, initialState, middlewares) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (typeof middlewares !== 'undefined') {
    const middlewareAPI = {
      getState: () => state,
      dispatch: (action) => dispatch(action),
    };

    const chain = middlewares.map((middleware) => middleware(middlewareAPI));
    const enhancedDispatch = compose(...chain)(dispatch);
    return { state, dispatch: enhancedDispatch };
  }

  return { state, dispatch };
};

const Provider = ({ children }) => {
  const { state, dispatch } = CreateStore(reducer, initialState, [customMiddleware]);
  const actions = useActions(state, dispatch);

  return <Store.Provider value={{ state, dispatch, actions }}>{children}</Store.Provider>;
};

const connect = (mapStateToProps = () => {}, mapDispatchToProps = () => {}) => (
  WrappedComponent,
) => {
  return (props) => {
    const { dispatch, state } = useContext(Store);
    return (
      <WrappedComponent
        dispatch={dispatch}
        {...mapStateToProps(state, props)}
        {...mapDispatchToProps(dispatch)}
      />
    );
  };
};

const useStore = () => useContext(Store);

export { Store, Provider, useStore, connect };

/********************
 * Simple State Mngmt
 *******************/

// import React, { createContext, useReducer, useMemo, useContext } from 'react';
// import reducer from './reducer';
// import initialState from './initialState';

// const Context = createContext(initialState);

// const Provider = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

//   console.log(state);
//   return <Context.Provider value={contextValue}>{children}</Context.Provider>;
// };

// export const useStore = () => useContext(Context);
// export default Provider;
