import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  paper: {
    margin: 'auto',
    width: 600,
    height: 300,
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2),
    // [theme.breakpoints.up('md')]: {
    //     width: '100%',
    //     height: 600,
    //     marginBottom: 0,
    // },
  },
  filledButton: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
});

const CouncilView = React.memo((props) => {
  const { classes, councilAddresses } = props;

  return (
    <section className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell align='right'>Address</TableCell>
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {councilAddresses.map((address) => (
              <TableRow key={address}>
                <TableCell component='th' scope='row'>
                  {'Canada'}
                </TableCell>
                <TableCell align='right'>{address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
});

CouncilView.propTypes = {
  classes: PropTypes.object.isRequired,
  councilAddresses: PropTypes.array.isRequired,
};

export default withStyles(styles)(CouncilView);
