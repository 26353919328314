import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Paper, Grid, TextField, Button, Divider } from '@material-ui/core';

// Store
import { useStore } from 'context';

const schema = yup.object().shape({
  councilAddress: yup.string().required('Please enter address you want to add as council'),
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  paper: {
    margin: 'auto',
    width: 600,
    height: 300,
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2),
    // [theme.breakpoints.up('md')]: {
    //     width: '100%',
    //     height: 600,
    //     marginBottom: 0,
    // },
  },
  filledButton: {
    width: '100%',
  },
  form: {
    width: '100%',
    margin: '0 auto',
  },
});

const RolesActionView = React.memo((props) => {
  const { classes } = props;
  const { actions } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, reset, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitAdd = async (data) => actions.addCouncil(data);
  const onSubmitRemove = async (data) => actions.removeCouncil(data);

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <form onSubmit={handleSubmit(onSubmitAdd)} className={classes.form}>
          <Paper className={classes.paper}>
            <Grid item xs={12} container direction='column' justify='center'>
              <Grid item container xs alignItems='flex-end'>
                <Typography gutterBottom variant='h4'>
                  Add New Council
                </Typography>
              </Grid>
              <Grid item container xs direction='column' alignItems='flex-start'>
                <Grid item container xs alignItems='flex-end'>
                  <Typography variant='h6'>Council Address</Typography>
                </Grid>
                <Grid item container xs justify='space-evenly'>
                  <TextField
                    id='councilAddress'
                    required
                    name='councilAddress'
                    inputRef={register}
                    placeholder='eg. 0x1276...'
                    margin='normal'
                    variant='outlined'
                    error={errors.councilAddress ? true : false}
                    helperText={errors.councilAddress ? errors.councilAddress.message : ''}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid item container xs justify='space-evenly' alignItems='flex-start'>
                <Button
                  color='primary'
                  className={classes.filledButton}
                  variant='contained'
                  type='submit'
                >
                  Add Council
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>

        {/* <form onSubmit={handleSubmit(onSubmitRemove)} className={classes.form}>
          <Paper className={classes.paper}>
            <Grid item xs={12} container direction='column' justify='center'>
              <Grid item container xs alignItems='flex-end'>
                <Typography gutterBottom variant='h4'>
                  Remove Existing Council
                </Typography>
              </Grid>
              <Grid item container xs direction='column' alignItems='flex-start'>
                <Grid item container xs alignItems='flex-end'>
                  <Typography variant='h6'>Council Address</Typography>
                </Grid>
                <Grid item container xs justify='space-evenly'>
                  <TextField
                    id='councilAddress'
                    required
                    name='councilAddress'
                    inputRef={register}
                    placeholder='eg. 0x1276...'
                    margin='normal'
                    variant='outlined'
                    error={errors.councilAddress ? true : false}
                    helperText={errors.councilAddress ? errors.councilAddress.message : ''}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid item container xs justify='space-evenly' alignItems='flex-start'>
                <Button
                  color='primary'
                  className={classes.filledButton}
                  variant='contained'
                  type='submit'
                >
                  Remove Council
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form> */}
      </Container>
    </section>
  );
});

RolesActionView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RolesActionView);
