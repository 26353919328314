import React from 'react';
import Footer from 'views/Footer';

const User = () => {
  return (
    <>
      <Footer />
    </>
  );
};

export default User;
