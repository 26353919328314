import { types } from './reducer';
import {
  mintBitmos,
  issueBitmos,
  transferBitmo,
  burnBitmo,
  getBitmo,
  addCouncil,
  removeCouncil,
  getAllBitmos,
  getBitmosByAddress,
} from 'blockchain';
import ProductHook from 'views/ProductHook';

// Promise helper
const PromiseGo = (promise) => promise.then((data) => [null, data]).catch((err) => [err]);

// Action hook
export const useActions = (state, dispatch) => ({
  error: (message) =>
    dispatch({
      type: types.ERROR,
      message,
    }),
  setWeb3: (web3) =>
    dispatch({
      type: types.SET_WEB3,
      web3,
    }),
  setChainId: (chainID) =>
    dispatch({
      type: types.SET_CHAIN_ID,
      chainID,
    }),
  setAddress: (walletAddress) =>
    dispatch({
      type: types.SET_ADDRESS,
      walletAddress,
    }),
  setNautifier: (nautifier) =>
    dispatch({
      type: types.SET_NAUTIFIER,
      nautifier,
    }),
  setReadContracts: (readContracts) =>
    dispatch({
      type: types.SET_READ_CONTRACTS,
      readContracts,
    }),
  setWriteContracts: (writeContracts) =>
    dispatch({
      type: types.SET_WRITE_CONTRACTS,
      writeContracts,
    }),
  resetAll: () =>
    dispatch({
      type: types.RESET_ALL,
    }),
  setLoading: (loading) =>
    dispatch({
      type: types.SET_LOADING,
      loading,
    }),
  // Read Funcs
  async getBitmo(tokenId) {
    const bitmoPromise = getBitmo({ tokenId, ...state });
    const [err, bitmo] = await PromiseGo(bitmoPromise);

    err && this.error(`[getBitmo] - ${err}`);

    // dispatch({
    //   type: types.BITMO,
    //   bitmo,
    // });

    return bitmo;
  },
  async getAllBitmos() {
    const allBitmos = getAllBitmos(state);
    const [err, bitmos] = await PromiseGo(allBitmos);

    err && this.error(`[getAllBitmos] - ${err}`);

    dispatch({
      type: types.BITMOS,
      bitmos,
    });
  },
  async getBitmosByAddress() {
    const balances = getBitmosByAddress(state);
    const [err, UserBitmos] = await PromiseGo(balances);

    err && this.error(`[getBitmosByAddress] - ${err}`);

    dispatch({
      type: types.GET_BALANCE_USER_BITMOS,
      UserBitmos,
    });
  },
  // Write Funcs
  async mintBitmos(tokenId) {
    const mint = mintBitmos({ tokenId, ...state });
    const [err, minted] = await PromiseGo(mint);

    dispatch({
      type: types.SET_LOADING,
      loading: false,
    });

    err && this.error(`[mintBitmos] - ${err}`);
  },
  async issueBitmos(bitmo) {
    const issue = issueBitmos({ bitmo, ...state });
    const [err, issued] = await PromiseGo(issue);

    // dispatch({
    //   type: types.SET_LOADING,
    //   loading: false,
    // });

    err && this.error(`[issueBitmos] - ${err}`);
  },
  async transferBitmo({ tokenId, quantity, toAddress }) {
    const transfer = transferBitmo({ tokenId, quantity, toAddress, ...state });
    const [err, transfered] = await PromiseGo(transfer);

    err && this.error(`[transferBitmo] - ${err}`);
  },
  async burnBitmo({ tokenId, quantity }) {
    const burn = burnBitmo({ tokenId, quantity, ...state });
    const [err, burned] = await PromiseGo(burn);

    err && this.error(`[burnBitmo] - ${err}`);
  },
  // Roles Funcs
  async addCouncil({ councilAddress }) {
    const add = addCouncil({ councilAddress, ...state });
    const [err, added] = await PromiseGo(add);

    err && this.error(`[addCouncil] - ${err}`);
  },
  async removeCouncil({ councilAddress }) {
    const remove = removeCouncil({ councilAddress, ...state });
    const [err, removed] = await PromiseGo(remove);

    err && this.error(`[removeCouncil] - ${err}`);
  },
});
