import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
    '& > *': {
      width: '100%',
      margin: theme.spacing(1),
    },
  },
  rightAlign: {
    marginLeft: 'auto',
  },
});

const FilterBar = (props) => {
  const { classes } = props;
  // TODO: Create new action to update a global context filter
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='primary'>
        <Tab label='ALL' />
        <Tab label='Active' />
        <Tab label='Retired' />
        <Tab label='Filters' className={classes.rightAlign} />
      </Tabs>
    </Paper>
  );
};

FilterBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterBar);
