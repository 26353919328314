import React, { useEffect } from 'react';
import Footer from 'views/Footer';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Typography from 'components/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(9),
  },
  text: {},
}));

const BitmoScan = () => {
  useEffect(() => {}, []);
  const classes = useStyles();

  return (
    <>
      <section className={classes.root}>
        <Container className={classes.container}>
          <Grid container wrap='nowrap'>
            <Grid container item xs={2}>
              {/* <BigLeaf /> */}
            </Grid>
            <Grid container item xs={12}>
              <Typography variant='h1' className={classes.text}>
                Coming Soon!
              </Typography>
            </Grid>
            <Grid container item xs={2}>
              {/* <SmallLeaves /> */}
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default BitmoScan;
