import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { useSnackbar } from 'notistack';
import Dropzone from 'components/DropZone';
import Typography from 'components/Typography';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField } from '@material-ui/core';

// Store
import { useStore } from 'context';

// MIGHT: have to move to external to be used from other files
const schema = yup.object().shape({
  name: yup.string().required('Please provide a project name'),
  description: yup.string().required('Please describe your project'),
  quantity: yup
    .number()
    .positive()
    .integer()
    .required('Provide the amount of credits you want to issue'),
  country: yup
    .string()
    .max(32)
    .required('Please provide location of this project'),
  scope: yup
    .number()
    .max(999)
    .positive()
    .integer()
    .required('Please provide the sectoral scope'),
  vintage: yup
    .number()
    .max(9999)
    .positive()
    .integer()
    .required('Please provide the vintage'),
  standard: yup.string().url(),
  serial: yup.string(),
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    position: 'relative',
    maxWidth: 1000,
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(10),
  },
  form: {
    width: '100%',
    margin: '0 auto',
  },
  textfield: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
    width: '100%',
  },
  filledButton: {
    background: theme.palette.secondary.background,
  },
});

const IssueForm = (props) => {
  const { classes } = props;
  const {
    state: { loading },
    actions,
  } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, reset, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const watchQuantity = watch('quantity');
  const onSubmit = async (data) => {
    actions.setLoading(true);
    actions.issueBitmos(data);
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container} disableGutters>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h4'>Issue new BITMOs</Typography>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={5} direction='column' justify='center' alignItems='stretch'>
                <Grid item xs>
                  <Typography variant='h6'>Project Name</Typography>
                  <TextField
                    id='name'
                    required
                    name='name'
                    inputRef={register}
                    placeholder='BFCF'
                    margin='normal'
                    variant='outlined'
                    error={errors.name ? true : false}
                    helperText={errors.name ? errors.name.message : ''}
                    className={classes.textfield}
                  />
                </Grid>

                <Grid item xs>
                  <Typography variant='h6'>Description</Typography>
                  <TextField
                    required
                    id='description'
                    multiline
                    rows='4'
                    name='description'
                    inputRef={register}
                    placeholder='Climate Change is real!'
                    margin='normal'
                    variant='outlined'
                    error={errors.description ? true : false}
                    helperText={errors.description ? errors.description.message : ''}
                    className={classes.textfield}
                  />
                </Grid>

                <Grid item xs>
                  <Typography variant='h6'>Number of BITMOs to be issued:</Typography>
                  <TextField
                    id='quantity'
                    required
                    name='quantity'
                    inputRef={register}
                    placeholder='10000'
                    margin='normal'
                    variant='outlined'
                    error={errors.quantity ? true : false}
                    helperText={errors.quantity ? errors.quantity.message : ''}
                    className={classes.textfield}
                  />
                </Grid>

                <Grid item xs>
                  <Typography variant='h6'>BITMOs Properties</Typography>
                  <Grid item container direction='column' justify='center' alignItems='stretch'>
                    <Grid item xs>
                      <TextField
                        required
                        id='country'
                        label='Country'
                        name='country'
                        inputRef={register}
                        placeholder='Canada'
                        margin='normal'
                        variant='outlined'
                        error={errors.country ? true : false}
                        helperText={errors.country ? errors.country.message : ''}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        required
                        id='scope'
                        label='Sectoral Scope'
                        name='scope'
                        inputRef={register}
                        placeholder='13'
                        margin='normal'
                        variant='outlined'
                        error={errors.scope ? true : false}
                        helperText={errors.scope ? errors.scope.message : ''}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        required
                        id='vintage'
                        label='Vintage'
                        name='vintage'
                        inputRef={register}
                        placeholder='2013'
                        margin='normal'
                        variant='outlined'
                        error={errors.vintage ? true : false}
                        helperText={errors.vintage ? errors.vintage.message : ''}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        required
                        id='standard'
                        label='Original Standard'
                        name='standard'
                        inputRef={register}
                        placeholder='https://carbonregistry.gov.bc.ca/br-reg/public/bc/project.jsp?project_id=104000000011514'
                        margin='normal'
                        variant='outlined'
                        error={errors.standard ? true : false}
                        helperText={errors.standard ? errors.standard.message : ''}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        required
                        id='serial'
                        label='Serial Number Range'
                        name='serial'
                        inputRef={register}
                        placeholder={`0-${watchQuantity}`}
                        margin='normal'
                        variant='outlined'
                        error={errors.serial ? true : false}
                        helperText={errors.serial ? errors.serial.message : ''}
                        className={classes.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={5} direction='column' justify='center' alignItems='stretch'>
                <Grid item xs>
                  <Typography variant='h6'>Upload Main Image</Typography>
                  <Controller
                    render={({ onChange }) => (
                      <Dropzone onChange={(e) => onChange(e?.target?.files[0])} />
                    )}
                    name='image'
                    control={control}
                    defaultValue=''
                  />
                </Grid>

                <Grid item xs>
                  <Typography variant='h6'>Upload Documents</Typography>
                  <Controller
                    render={({ onChange }) => (
                      <Dropzone onChange={(e) => onChange(e?.target?.files)} />
                    )}
                    name='documents'
                    control={control}
                    defaultValue=''
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs item container direction='column' justify='center' alignItems='center'>
              <Button
                disabled={loading}
                color='primary'
                className={classes.filledButton}
                variant='contained'
                type='submit'
              >
                Issue BITMOs
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </section>
  );
};

IssueForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IssueForm);
