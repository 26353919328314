const prod = {
  network: {
    ethereumNetwork: 'mainnet',
    addresses: {
      bitmo: '0xd457ca318244cdfcfc9751d2e0c4cf9b59dfc91a',
    },
    mainnetProvider: 'https://mainnet.infura.io/v3/93c1a5b0b9f942779ff7fa51ae17f0c1',
    creator: '',
  },
  graph: {
    uri: '',
  },
  opensea: {
    OSURI: `https://testnets.opensea.io/assets/${require('contracts/Bitmo.address')}`,
  },
  reactGA: {
    trackingId: '',
    gaOptions: {
      testMode: false,
    },
  },
};

const dev = {
  network: {
    ethereumNetwork: 'rinkeby',
    addresses: {
      bitmo: '0xF367cafD33C61a5FB4E4AD214a69E4596b9BC8EB',
    },
    rinkebyProvider: 'https://rinkeby.infura.io/v3/fa10fe665dfc443d998688ab5b4ed58b',
    creator: '',
  },
  graph: {
    uri: '',
  },
  opensea: {
    OSURI: `https://testnets.opensea.io/assets/${require('contracts/Bitmo.address')}`,
  },
  reactGA: {
    trackingId: '',
    gaOptions: {
      testMode: true,
    },
  },
};

export const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';
export const ETHERSCAN_KEY = 'PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8';
export const COMMON = process.env.NODE_ENV === 'development' ? dev : prod;
