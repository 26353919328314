import React from 'react';
import Footer from 'views/Footer';
import Bitmos from 'views/Bitmos';
import { useBitmosByAddress, useBitmoFilter } from 'hooks';
import TopProfileBanner from 'components/TopProfileBanner';

// Store
import { useStore } from 'context';

const Profile = () => {
  const {
    state: { walletAddress, readContracts },
  } = useStore();
  const bitmosByAddress = useBitmosByAddress(readContracts, walletAddress);
  // const filteredBitmos = useBitmoFilter(readContracts, {walletAddress});

  return (
    <>
      {bitmosByAddress && (
        <section>
          <Bitmos bitmos={bitmosByAddress} page='profile'>
            <TopProfileBanner />
          </Bitmos>
          <Footer />
        </section>
      )}
    </>
  );
};

export default Profile;
