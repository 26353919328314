import React from 'react';
import Footer from 'views/Footer';
import TransferView from 'views/TransferView';

const Transfer = ({ match }) => {
  const {
    params: { tokenId },
  } = match;

  return (
    <>
      <TransferView tokenId={tokenId} />
      <Footer />
    </>
  );
};

export default Transfer;
