// import Bitmo from 'contracts/Bitmo.json';
import { utils } from 'ethers';
import IPFS from 'services/ipfs';
import BigNumber from 'bignumber.js';
import { useContractReader } from 'hooks';

const { solidityKeccak256 } = utils;

// https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
// https://docs.opensea.io/docs/metadata-standards
// const tokenMetadata = {
//   description: '',
//   external_url: '',
//   animation_url: '',
//   youtube_url: '',
//   image: '',
//   image_data: '',
//   name: '',
//   background_color: 'FFF',
//   // properties: {},
//   attributes: [
//     {
//       key: 'Country',
//       trait_type: 'Country',
//       value: '',
//     },
//     {
//       key: 'Scope',
//       trait_type: 'Scope',
//       value: '',
//     },
//     {
//       key: 'Vintage',
//       trait_type: 'Vintage',
//       value: '',
//     },
//     {
//       key: 'Documents',
//       trait_type: 'Documents',
//       value: '',
//     },
//     {
//       key: 'Original Standard',
//       trait_type: 'Original Standard',
//       value: '',
//     },
//     {
//       key: 'Serial Number Range',
//       trait_type: 'serial',
//       value: '',
//     },
//   ], // OpenSea
// };

const tokenMetadata = {
  name: '',
  description: '',
  animation_url: '',
  image: '',
  documents: '',
  external_url: '',
  attributes: [
    {
      key: 'Country',
      trait_type: 'Country',
      value: '',
    },
    {
      key: 'Scope',
      trait_type: 'Scope',
      value: '',
    },
    {
      key: 'Vintage',
      trait_type: 'Vintage',
      value: '',
    },
    // {
    //   key: 'Documents',
    //   trait_type: 'Documents',
    //   value: '',
    // },
    {
      key: 'Original Standard',
      trait_type: 'Standard',
      value: '',
    },
    {
      key: 'Serial Number Range',
      trait_type: 'Serial',
      value: '',
    },
  ],
};

/****************
 * Common Helpers
 ****************/
const map = (func) => (arr) => arr.map(func);
const awaitAll = (promiseArray) => Promise.all(promiseArray);
const supplyArray = (count) => Array.from(Array(parseInt(count)).keys());
const PromiseGo = (promise) => promise.then((data) => [null, data]).catch((err) => [err]);
const compose = (method) => (...funcs) => funcs.reduce((f, g) => async (x) => g(x)[method](f));

const spyBitmo = (readContracts) => async (tokenId) => {
  const [uri, bitmo, quantity] = await Promise.all([
    getContractData(readContracts, 'uri', tokenId),
    getContractData(readContracts, 'bitmos', tokenId),
    getContractData(readContracts, 'totalSupply', tokenId),
  ]);

  const metadata = await Promise.all([uri].map(async (uri) => await IPFS.fetchJsonFromIpfs(uri)));

  return { uri, bitmo, quantity, metadata: metadata[0], tokenId };
};

/**********************
 * IPFS service helpers
 *********************/
export const saveMetadata = async ({
  image,
  name,
  documents,
  description,
  country,
  scope,
  vintage,
  standard,
  serial,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Upload main image to ipfs
      const imageHash = await IPFS.saveFileToIPFS(image);

      // Upload documents
      const documentsHashes = await IPFS.saveDocsToIPFS(documents);

      const traittMap = (trait_type) =>
        ({
          Country: country,
          Scope: scope,
          Vintage: vintage,
          Standard: standard,
          Serial: serial,
        }[trait_type]);

      // Create object compatiable with OpenSea
      const metadata = {
        ...tokenMetadata,
        name,
        description,
        external_url: `https://www.bitmo.org/api/v1/metadata/type/{id}`, // move to globals file
        image: imageHash,
        documents: documentsHashes,
        attributes: tokenMetadata.attributes.map(({ trait_type, ...attr }) => ({
          ...attr,
          trait_type,
          value: traittMap(trait_type) ? String(traittMap(trait_type)) : '',
        })),
      };

      // Save Metadata to ipfs
      const metadataHash = await IPFS.saveJsonFile({ metadata });

      resolve({ metadataHash });
    } catch (error) {
      console.log({ error });
      reject(error);
    }
  });
};

/***************************
 * Bitmo contract - issuance
 ***************************/
export const issueBitmos = async ({ writeContracts, nautifier, walletAddress, bitmo }) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const params = [
      //   '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266', // _to
      //   10000, // _quantity to issue initally
      //   'ipfs/QmXFZbPY55SZEir9jvMvW11HJzzDatrdBYtnV6a2SxrC3U', // _uri token type uri ipfs://ipfs/{hash}
      //   '0x', // _data contract data
      //   7, // _scope
      //   2012, // _vintage
      //   utils.formatBytes32String('Canada'),
      // ];

      // const issueBitmoTx = nautifier(writeContracts.Bitmo.issueBitmos(...params));

      const { metadataHash } = await saveMetadata(bitmo);
      const { quantity, country, scope, vintage } = bitmo;

      // Convert Ascii string to hex => contract accepts byte32
      const countryHex = utils.formatBytes32String(country);

      // payload
      const params = [
        walletAddress, // _to
        quantity, // _quantity to issue initally
        metadataHash, // _uri token type uri ipfs://ipfs/{hash}
        '0x', // _data contract data
        scope, // _scope
        vintage, // _vintage
        countryHex, // _country
      ];

      const issueBitmoTx = nautifier(writeContracts.Bitmo.issueBitmos(...params));

      resolve({ params, issueBitmoTx });
    } catch (error) {
      reject(error);
    }
  });
};

export const mintBitmos = async ({ writeContracts, nautifier, walletAddress, bitmo }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { quantity, tokenId } = bitmo;

      const params = [
        walletAddress, // _to
        new BigNumber(tokenId), // _id token type to mint
        new BigNumber(quantity), // _quantity to mint
        '0x', // _data contract data
      ];

      const mintBitmosTx = nautifier(writeContracts.Bitmo.issueBitmos(...params)); // mintBitmos not issue

      resolve({ walletAddress, tokenId, mintBitmosTx });
    } catch (error) {
      console.log({ error });
      reject(false);
    }
  });
};

/***************************
 * Bitmo contract - transfer
 ***************************/
export const transferBitmo = async ({
  writeContracts,
  nautifier,
  walletAddress: fromAddress,
  toAddress,
  tokenId,
  quantity,
}) => {
  return new Promise((resolve, reject) => {
    try {
      const transferBitmoTx = nautifier(
        writeContracts.Bitmo.safeTransferFrom(fromAddress, toAddress, tokenId, quantity, '0x'),
      );

      resolve(transferBitmoTx);
    } catch (error) {
      reject(error);
    }
  });
};

/***********************
 * Bitmo contract - burn
 ***********************/
export const burnBitmo = async ({
  writeContracts,
  nautifier,
  walletAddress: fromAddress,
  tokenId,
  quantity,
}) => {
  return new Promise((resolve, reject) => {
    try {
      const burnBitmoTx = nautifier(writeContracts.Bitmo.burn(fromAddress, tokenId, quantity));

      resolve(burnBitmoTx);
    } catch (error) {
      reject(error);
    }
  });
};

/************************
 * Bitmo contract - roles
 ************************/
export const addCouncil = async ({ writeContracts, nautifier, councilAddress }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const add = await nautifier(writeContracts.Bitmo.addCouncil(councilAddress));

      resolve(add);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeCouncil = async ({ writeContracts, nautifier, councilAddress }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const remove = await nautifier(writeContracts.Bitmo.removeCouncil(councilAddress));

      resolve(remove);
    } catch (error) {
      reject(error);
    }
  });
};

/***********************
 * Bitmo contract - call -- moved to hooks for now
 ***********************/
export const getBalanceNativeToken = async (web3, address) => {
  return web3 && web3.utils.fromWei(await web3.eth.getBalance(address), 'ether');
};

export const getBitmo = async ({ readContracts, tokenId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await spyBitmo(readContracts)(tokenId);

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getContractData = async (readContracts, method, ...params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = useContractReader(readContracts, 'Bitmo', method, params);

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBalanceBitmo = async (instanceBitmo) => {
  return new Promise(async (resolve, reject) => {
    try {
      // resolve(bitmoBa)
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllBitmos = async ({ readContracts }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bitmosCount = await getContractData(readContracts, 'currentTokenId');

      const allBitmoPromises = compose('map')(spyBitmo(readContracts), supplyArray);
      const allBitmos = compose('then')(awaitAll, allBitmoPromises)(bitmosCount);

      resolve(allBitmos);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBitmosByAddress = async ({ walletAddress, readContracts }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bitmosCount = await getContractData(readContracts, 'currentTokenId');

      const balanceOf = (tokenId) =>
        getContractData(readContracts, 'balanceOf', walletAddress, tokenId);

      /**
       * @param {*} balance array of balances
       * @returns get indices of all the non zero balances
       */
      const ownedTypeFilter = (balance) =>
        Promise.resolve(balance.reduce((r, d, i) => (d > 0 ? (r.push(i), r) : r), []));

      const allBalancesPromises = compose('map')(balanceOf, supplyArray);
      const allBitmoPromises = compose('map')(spyBitmo(readContracts));
      const allBalances = compose('then')(
        map(allBitmoPromises),
        ownedTypeFilter,
        awaitAll,
        allBalancesPromises,
      )(bitmosCount);

      const [err, data] = await PromiseGo(allBalances);
      const balances = Promise.all(data);

      resolve(balances);
    } catch (error) {
      reject(error);
    }
  });
};
