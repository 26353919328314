import React from 'react';
import Footer from 'views/Footer';
import { useCouncilCount } from 'hooks';
import RolesActionView from 'views/RolesActionView';
import CouncilView from 'views/CouncilView';

// Store
import { useStore } from 'context';

const Roles = () => {
  const {
    state: { readContracts },
  } = useStore();
  const councilAddresses = useCouncilCount(readContracts);

  return (
    <>
      <RolesActionView />

      {councilAddresses ? <CouncilView councilAddresses={councilAddresses} /> : null}
    </>
  );
};

export default Roles;
