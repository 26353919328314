// Actions
const types = {
  ERROR: 'ERROR',
  BITMO: 'BITMO',
  BITMOS: 'BITMOS',
  SET_WEB3: 'SET_WEB3',
  RESET_ALL: 'RESET_ALL',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_LOADING: 'SET_LOADING',
  SET_CHAIN_ID: 'SET_CHAIN_ID',
  SET_NAUTIFIER: 'SET_NAUTIFIER',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',

  SET_READ_CONTRACTS: 'SET_READ_CONTRACTS',
  SET_WRITE_CONTRACTS: 'SET_WRITE_CONTRACTS',

  GET_BALANCE_USER_BITMOS: 'GET_BALANCE_USER_BITMOS',
  GET_BALANCE_NATIVE_TOKEN: 'GET_BALANCE_NATIVE_TOKEN',
};

/*********************************
 * Initial State Object for Bitmo
 *********************************/

const initialState = {
  error: null,
  bitmos: [],
  web3: 'null',
  loading: false,
  chainID: null,
  UserBitmos: [],
  nautifier: null,
  isLoggedIn: false,
  walletAddress: null,
  readContracts: null,
  writeContracts: null,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case types.SET_WEB3:
      return {
        ...state,
        web3: action.web3,
      };
    case types.RESET_ALL:
      return {
        ...state,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case types.SET_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case types.ERROR:
      return {
        ...state,
        message: action.message,
      };
    case types.SET_CHAIN_ID:
      return {
        ...state,
        chainID: action.chainID,
      };
    case types.BITMO:
      return {
        ...state,
        bitmos: action.bitmo,
      };
    case types.BITMOS:
      return {
        ...state,
        bitmos: action.bitmos,
      };
    case types.SET_NAUTIFIER:
      return {
        ...state,
        nautifier: action.nautifier,
      };
    case types.GET_BALANCE_USER_BITMOS:
      return {
        ...state,
        UserBitmos: action.UserBitmos,
      };
    case types.GET_BALANCE_NATIVE_TOKEN:
      return {
        ...state,
      };
    case types.SET_ADDRESS:
      return {
        ...state,
        walletAddress: action.walletAddress,
      };
    case types.SET_READ_CONTRACTS:
      return {
        ...state,
        readContracts: action.readContracts,
      };
    case types.SET_WRITE_CONTRACTS:
      return {
        ...state,
        writeContracts: action.writeContracts,
      };
    default:
      return state;
  }
};

export { types, initialState, reducer };
