import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(10),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
  },
});

const ProductHook = (props) => {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid direction='column' container spacing={2}>
          <Grid item xs className={classes.item}>
            <Typography variant='h6' className={classes.title}>
              The BiTMO Platform
            </Typography>
          </Grid>

          <Grid item xs className={classes.item}>
            <Typography variant='h5' className={classes.title}>
              The BITMO Platform enables secure minting of ITMOs as ERC-1155 Non-Fungible Tokens
              (NFTs) that display key quality and provenance characteristics on each 1tCO2e token,
              transparently and sortably, while embedding all necessary detailed project design and
              audit documents in PDF format. This allows seamless trading and exchange of ITMOs, be
              it one tonne or a billion, across the dominant blockchain ecosystem, be it from
              Ethereum wallet to wallet, on the BITMO Platform, or across Opensea. The Blockchain
              for Climate Foundation team have built the BITMO Platform to encourage and enable
              national governments to move farther, faster, on the goal of international
              collaboration of beating climate change. Can you negotiate harder for completion of an
              Article 6 rulebook if you know you won’t have to wait years for the architecture
              needed to issue and exchange Article 6.2 ITMOs? Are you willing to go out on a limb
              and trade ITMOs with your partner nations in advance of the rulebook, because you know
              that the carbon credits will be clearly and transparently issued, tracked between
              countries, and retired as part of your biennial report when the time comes? Are you
              impatient to usher in an age of cross-border collaboration?
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

ProductHook.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHook);
