import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  dropzone: {
    margin: 'auto',
    display: 'flex',
    outline: 'none',
    height: '20vmin',
    borderRadius: 20,
    cursor: 'pointer',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${theme.palette.secondary.main}`,
    input: {
      display: 'none',
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
});

const CustomDropzone = (props) => {
  const { classes, onChange } = props;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        {/* <img
          src={file.preview}
          className={classes.img}
        /> */}
        <p>{file.path}</p>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <div {...getRootProps()} className={classes.dropzone}>
      <input type='file' name='file' {...getInputProps({ onChange })} />
      <p>Drag 'n' drop some files here, or click to select files</p>
      <aside className={classes.thumbsContainer}>{thumbs}</aside>
    </div>
  );

  // const onDrop = useCallback(acceptedFiles => {
  //   // Do something with the files
  //   props.input.onChange(acceptedFiles);
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: "image/jpeg, image/png"
  // });

  // return (
  //   <div {...getRootProps()}>
  //     <input {...getInputProps()} {...props} />
  //     {isDragActive ? (
  //       <p>Drop the files here ...</p>
  //     ) : (
  //       <p>Drag 'n' drop some files here, or click to select files</p>
  //     )}
  //     {props.input.value
  //       ? props.input.value.map(file => {
  //           return <div>{file.path}</div>;
  //         })
  //       : null}
  //   </div>
  // );
};

CustomDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomDropzone);
