import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    img: {
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: theme.spacing(2),
    },
    media: {
        width: '90%',
        maxWidth: 220,
        maxHeight: 220,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(-3),
        // height: 0,
        paddingBottom: '48%',
        borderRadius: theme.spacing(2),
        backgroundColor: '#fff',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          width: '100%',
          marginLeft: theme.spacing(3),
          // marginLeft: theme.spacing(-3),
          marginTop: 0,
          transform: 'translateX(-8px)',
        },
    },
});

const LazyImage = ({ classes, src, alt, lqip, aspectRatio = 2 / 3 }) => {
    const [loaded, setLoaded] = useState(false);
    
    const imgRef = useRef();

    useEffect(() => {
      if (imgRef.current && imgRef.current.complete) {
        setLoaded(true);
      }
    }, []);

    return (
        <LazyLoad 
          className={classes.media} 
          debounce={false}
          offsetVertical={500}
          throttle
        //   placeholder={<img src={`https://picsum.photos/id/200/220/220`} alt="..." />}
        >
            <CardMedia
              className={classes.img}
              image={src}
            />

            {/* <img
                loading="lazy"
                src={src}
                alt={alt}
                ref={imgRef}
                onLoad={() => setLoaded(true)}
                className={classes.img}
            /> */}
        </LazyLoad>
    );
};

LazyImage.propTypes = {
    classes: PropTypes.object.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};
  
export default withStyles(styles)(LazyImage);
  