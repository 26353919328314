import React from 'react';
import { utils } from 'ethers';
import PropTypes from 'prop-types';
import { getCode } from 'country-list';
import { Link } from 'react-router-dom';
import LazyImage from 'components/LazyImage';
// import { useGenerateLazyImage } from 'hooks';
import Typography from 'components/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, CardContent, Divider } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 'auto',
    borderRadius: theme.spacing(2), // 16px
    // transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 500,
    maxHeight: 272,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: theme.spacing(2),
    },
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
      transform: 'scale(1.04)',
    },
    // transition: '0.2s',
    // '&:hover': {
    //   transform: 'scale(1.1)',
    // },
  },
  img: {
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    borderRadius: theme.spacing(2),
  },
  media: {
    width: '90%',
    maxWidth: 220,
    maxHeight: 220,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(-3),
    // height: 0,
    paddingBottom: '48%',
    borderRadius: theme.spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: theme.spacing(3),
      // marginLeft: theme.spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
  },
  flag: {
    width: 64,
    height: 32,
    maxWidth: 64,
    maxHeight: 32,
    marginLeft: 'auto',
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-3),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#fff',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 32,
      marginTop: 0,
      marginRight: theme.spacing(-1),
      transform: 'translateX(-8px)',
    },
  },
  title: {
    fontFamily: "'Sen', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 800,
    color: 'black',
  },
  // overline: {
  //   textTransform: 'uppercase',
  //   letterSpacing: '1px',
  //   fontSize: 12,
  //   marginBottom: '0.875em',
  //   display: 'inline-block'
  // },
  // heading: {
  //   fontSize: 20,
  //   fontWeight: 'bold',
  //   marginBottom: '0.35em',
  //   fontFamily: family
  // },
  textContent: {
    [theme.breakpoints.up('md')]: {
      justify: 'space-evenly',
      alignItems: 'stretch',
    },
  },
  divider: {
    width: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: theme.spacing(1.5),
    },
  },
});

// Helpers
const scopeMap = (scope) =>
  ({
    1: 'Energy industries (renewable - / non-renewable sources)',
    2: 'Energy distribution',
    3: 'Energy demand',
    4: 'Manufacturing industries',
    5: 'Chemical industries',
    6: 'Construction',
    7: 'Transport',
    8: 'Mining/mineral production',
    9: 'Metal production',
    10: 'Fugitive emissions from fuels (solid, oil and gas)',
    11: 'Fugitive emissions from production and consumption of halocarbons and sulphur hexafluoride',
    12: 'Solvent use',
    13: 'Waste handling and disposal',
    14: 'Afforestation and reforestation',
    15: 'Agriculture',
  }[scope]);

const FLAG = (country) => {
  const countryName = hexToString(country);

  // there is two koreas duh
  let code = countryName === 'Korea' ? 'kr' : getCode(countryName)?.toLowerCase();

  if (countryName.toLowerCase() === 'usa') code = 'us'; // this is dumb fix later quick one before travel

  return require(`services/flags/4x3/${code}.svg`); // TODO: add a fail safe import of an image placeholder
};

const hexToString = (hex) => utils.parseBytes32String(hex);
const ipfsPathToHash = (path) => path.replace('ipfs://ipfs/', '');
const numberFormat = (quantity) => quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const limitText = (value, limit) => (value.length > limit ? `${value.slice(0, limit)}...` : value);

const BitmoCardStore = React.memo(
  ({
    classes,
    bitmo: {
      bitmo: { country, scope, vintage },
      metadata: { name, image },
      quantity,
      tokenId,
    },
  }) => {
    // const { src, aspectRatio, lqip } = useGenerateLazyImage(`https://cloudflare-ipfs.com/ipfs/${ipfsPathToHash(image)}`);

    return (
      <Link to={`/bitmo/${tokenId}`} style={{ textDecoration: 'none' }}>
        <Card className={classes.root}>
          <LazyImage 
            src={`https://cloudflare-ipfs.com/ipfs/${ipfsPathToHash(image)}`}
            alt={name}
          />

          <CardContent>
            <Grid container spacing={3}>
              <Grid item container xs={12} spacing={3} justify='space-evenly' alignItems='flex-end'>
                <Grid item xs>
                  <Typography variant='overline' color='textSecondary' component='h2'>
                    {vintage}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <CardMedia className={classes.flag} image={FLAG(country)} />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                spacing={3}
                direction='column'
                justify='space-evenly'
                alignItems='center'
                className={classes.textContent}
              >
                <Divider variant='inset' classes={{ inset: classes.divider }} />
                <Grid item xs>
                  <Typography variant='subtitle2' color='textSecondary' component='h2'>
                    {limitText(scopeMap(scope), 27)}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {limitText(name, 40)}
                  </Typography>
                </Grid>
                <Divider variant='inset' classes={{ inset: classes.divider }} />
                <Grid item xs>
                  <Typography variant='overline' color='textSecondary' component='h2'>
                    {numberFormat(quantity)} of {numberFormat(quantity)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    );
  },
);

BitmoCardStore.propTypes = {
  classes: PropTypes.object.isRequired,
  bitmo: PropTypes.object.isRequired,
};

export default withStyles(styles)(BitmoCardStore);