import { useState, useCallback, useEffect } from 'react';

const useCouncil = (readContracts, walletAddress) => {
  const [isCouncil, setIsCouncil] = useState(false);

  const execute = useCallback(async () => {
    if (walletAddress) {
      const isCouncil = () => readContracts.Bitmo.isCouncil(walletAddress);

      isCouncil().then((result) => setIsCouncil(result));
    }
  }, [readContracts, walletAddress]);

  useEffect(() => {
    readContracts && execute();
  }, [execute, readContracts]);

  return isCouncil;
};

export default useCouncil;
