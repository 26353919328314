import { useState, useEffect, useCallback } from 'react';
import IPFS from 'services/ipfs';
import { useContractReader } from '.';
import { constants, utils } from 'ethers';

// running your geth node -> event listener -> filters
// cache and grouping some of the functions to work more effiencetly
// orchasteration layer
// absuing your own potenial nodes 
// propgate events geth 

// Store
import { useStore } from 'context';

// Constants
const { AddressZero } = constants;
const { solidityKeccak256 } = utils;

// keccak256 hashes of each role
const DEFAULT_ADMIN_ROLE = '0x0000000000000000000000000000000000000000000000000000000000000000';
const COUNCIL_ROLE = solidityKeccak256(['string'], ['COUNCIL_ROLE']);

/****************
 * Common Helpers
 ****************/
// const mapObjectMapToFunc = (func) => (arr) => arr.map(params => func(...Object.values(params)))

const map = (func) => (arr) => arr.map(func);
const awaitAll = (promisesArray) => Promise.all(promisesArray);
const supplyArray = (count) => {
  const arr = count ? Array.from(Array(parseInt(count)).keys()) : [];

  return arr.reduce((acc, current) => (acc.push({ tokenId: current }), acc), []);
};
const PromiseGo = (promise) => promise.then((data) => [null, data]).catch((err) => [err]);
const compose = (method) => (...funcs) => funcs.reduce((f, g) => async (x) => g(x)[method](f));
// const compose = (...fns) => res => fns.reduce((accum, next) => next(accum), res)

/************************
 * Callback hook function
 ************************/
const getContractData = (contractReader, readContracts, ...params) =>
  contractReader(readContracts, 'Bitmo', ...params);

/***************************************
 * Get all the data for a one Bitmo Type
 ***************************************/
const spyBitmo = (readContracts) => async ({ tokenId, ownedBalance }) => {
  const [uri, bitmo, quantity] = await Promise.all([
    readContracts.Bitmo.uri(tokenId),
    readContracts.Bitmo.bitmos(tokenId),
    ownedBalance ?? readContracts.Bitmo.totalSupply(tokenId),
  ]);

  // const metadata = {"description":"","external_url":"https://www.bitmo.org/api/v1/metadata/type/{id}","animation_url":"","youtube_url":"","image":"ipfs://ipfs/QmWUg5Xd9pu4Yhbc8SoMfvyczKfisYAec1EVvpaN6Rdk6B","image_data":"","name":"","background_color":"FFF","properties":{},"attributes":[[{"key":"Country","value":"Canada","trait_type":"country"},{"key":"Scope","value":14,"trait_type":"scope"},{"key":"Vintage","value":1996,"trait_type":"vintage"},{"key":"Documents","trait_type":"documents"},{"key":"Original Standard","value":"http://example.com","trait_type":"standard"}]]}
  const metadata = await IPFS.fetchJsonFromIpfs(uri);

  return { uri, bitmo, quantity, metadata, tokenId };
};

export const useIsCurrentOwner = (readContracts, tokenId, walletAddress) => {
  const { actions } = useStore();
  const [isTokenOwner, setIsTokenOwner] = useState(false);

  const execute = useCallback(() => {
    const balanceOf = () => readContracts.Bitmo.balanceOf(walletAddress, tokenId);

    return balanceOf().then((response) =>
      response > 0 ? setIsTokenOwner(true) : setIsTokenOwner(false),
    );
  }, [readContracts, walletAddress, tokenId]);

  useEffect(() => {
    execute();
  }, [execute]);

  return { isTokenOwner };
};

export const useBitmoFilter = (readContracts, { walletAddress, filter }) => {
  const { actions } = useStore();
  const [filteredBitmos, setFilteredBitmos] = useState();
  const bitmosByAddress = useBitmosByAddress(readContracts, AddressZero);
  const allBitmos = useAllBitmos(readContracts);

  useEffect(() => {
    const load = async () => {
      if (readContracts) {
        const bitmos = bitmosByAddress; // needs major rethinking

        console.log(bitmos);
        // const applyFilter = (bitmo) => console.log(bitmo);
        // const applyFilter = cb => arr => arr.filter(cb);

        // const allBalances = compose('then')(
        //   map(applyFilter()),
        // )(bitmos);

        // const [err, filteredBitmos] = await PromiseGo(allBalances);

        // if (err) {
        //   console.log(err);
        //   // actions.error(`[getBitmosByAddress] - ${err}`);
        //   return;
        // }

        // setFilteredBitmos(filteredBitmos);
      }
    };

    load();
  }, [readContracts, walletAddress, bitmosByAddress, allBitmos]);

  return filteredBitmos;
};

export const useBitmoOwners = (readContracts, tokenId) => {
  const { actions } = useStore();
  const [bitmoOwners, setBitmoOwners] = useState();
};

export const useCouncilCount = (readContracts) => {
  const { actions } = useStore();
  const [councilAddresses, setCouncilAddresses] = useState();

  const execute = useCallback(async () => {
    if (readContracts) {
      const getRoleMemberCount = () => readContracts.Bitmo.getRoleMemberCount(COUNCIL_ROLE);
      const getRoleMember = (index) => readContracts.Bitmo.getRoleMember(COUNCIL_ROLE, index);
      const getCountArray = (count) => Array.from(Array(parseInt(count)).keys());

      const memberCountMap = compose('map')(getRoleMember, getCountArray);
      const roleAddresses = compose('then')(awaitAll, memberCountMap, getRoleMemberCount)();

      const [err, councilAddresses] = await PromiseGo(roleAddresses);
      if (err) {
        console.log(err);
        // actions.error(`[getBitmosByAddress] - ${err}`);
        return;
      }

      setCouncilAddresses(councilAddresses);
    }
  }, [readContracts]);

  useEffect(() => {
    execute();
  }, [execute]);

  return councilAddresses;
};

export const useBitmo = (readContracts, tokenId) => {
  const { actions } = useStore();
  const [bitmo, setBitmo] = useState();

  useEffect(() => {
    const load = async () => {
      if (readContracts && tokenId) {
        const [err, bitmo] = await PromiseGo(spyBitmo(readContracts)({ tokenId }));
        if (err) {
          console.log({ err });
          actions.error(`[getBitmosByAddress] - ${err}`);
          return;
        }

        setBitmo(bitmo);
      }
    };

    load();
  }, [readContracts, tokenId]);

  return bitmo;
};

export const useAllBitmos = (readContracts) => {
  const { actions } = useStore();
  const [allBitmos, setAllBitmos] = useState();
  // const bitmosCount = getContractData(useContractReader, readContracts, 'currentTokenId');

  useEffect(() => {
    const load = async () => {
      if (readContracts) {
        const bitmosCount = readContracts && (await readContracts.Bitmo.currentTokenId());

        const allBitmoPromises = compose('map')(spyBitmo(readContracts), supplyArray);
        const result = compose('then')(awaitAll, allBitmoPromises)(bitmosCount);

        const [err, allBitmos] = await PromiseGo(result);
        if (err) {
          console.log({ err });
          actions.error(`[getAllBitmos] - ${err}`);
          return;
        }

        setAllBitmos(allBitmos);
      }
    };

    load();
  }, [readContracts, actions]);

  return allBitmos;
};

export const useBitmosByAddress = (readContracts, walletAddress) => {
  const { actions } = useStore();
  const [bitmosByAddress, setBitmosByAddress] = useState();
  // const bitmosCount = getContractData(useContractReader, readContracts, 'currentTokenId');

  /**
   * @param {*} balance array of balances
   * @returns get indices of all the non zero balances
   */
  const ownedTypeFilter = (balance) =>
    Promise.resolve(
      balance.reduce(
        (acc, ownedBalance, i) =>
          ownedBalance > 0 ? (acc.push({ tokenId: i, ownedBalance }), acc) : acc,
        [],
      ),
    );

  useEffect(() => {
    const load = async () => {
      if (readContracts && walletAddress) {
        const bitmosCount = readContracts && (await readContracts.Bitmo.currentTokenId());
        const balanceOf = ({ tokenId }) => readContracts.Bitmo.balanceOf(walletAddress, tokenId);

        // Composoble function chain to get bitmos by address
        const allBitmoPromises = compose('map')(spyBitmo(readContracts));
        const allBalancesPromises = compose('map')(balanceOf, supplyArray);
        const allBalances = compose('then')(
          map(spyBitmo(readContracts)),
          // map(allBitmoPromises),
          ownedTypeFilter,
          awaitAll,
          allBalancesPromises,
        )(bitmosCount);

        ///////////////////////////////////////////////////////////
        let [err, bitmosByAddress] = await PromiseGo(allBalances);
        // // console.log(err)
        if (err) {
          console.log(err);
          // actions.error(`[getBitmosByAddress] - ${err}`);
          return;
        }

        const balances = Promise.all(bitmosByAddress);
        [err, bitmosByAddress] = await PromiseGo(balances);
        if (err) {
          actions.error(`[getBitmosByAddress] - ${err}`);
          return;
        }
        /////////////////////////////////////////////////////////// - fix this part there def a better way

        setBitmosByAddress(bitmosByAddress);
      }
    };

    load();
  }, [readContracts, walletAddress, actions]);

  return bitmosByAddress;
};
