import React from 'react';
import IssueForm from 'views/IssueForm';

const Issue = () => {
  return (
    <>
      <IssueForm />
    </>
  );
};

export default Issue;
